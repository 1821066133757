import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StaticDataService {

    private _cars$ = new BehaviorSubject<CarBrand[]>(undefined);

    constructor(private db: AngularFirestore) {

    }



    carBrands(): Observable<CarBrand[]> {

        if (!this._cars$.value) {

            return this.db.collection("systemData").doc("data").get().pipe(
                map(doc => {
                    const data: any = doc.data();

                    return data.carBrands as CarBrand[];
                }),
                tap(carBrands => this._cars$.next(carBrands || [])),
                switchMap(_ => {
                    return this._cars$.asObservable();
                })
            )
        } else {
            return this._cars$.asObservable();
        }
    }
}


export interface CarBrand {
    id: string;
    name: string;
}