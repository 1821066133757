import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { FormAnimals } from "../model/form/form-element/form-animals";
import { AvailabilityType, FormAvailability } from "../model/form/form-element/form-availability";
import { FormBoolean } from "../model/form/form-element/form-boolean";
import { FormCars } from "../model/form/form-element/form-cars";
import { FormCheckbox } from "../model/form/form-element/form-checkbox";
import { FormDatetime } from "../model/form/form-element/form-datetime";
import { FormDropdown } from "../model/form/form-element/form-dropdown";
import { FormElement, FormElementType } from "../model/form/form-element/form-element";
import { FormEquipments } from "../model/form/form-element/form-equipments";
import { FormFileupload } from "../model/form/form-element/form-fileupload";
import { FormHeight } from "../model/form/form-element/form-height";
import { FormImageupload } from "../model/form/form-element/form-imageupload";
import { FormLanguages } from "../model/form/form-element/form-languages";
import { FormNumber } from "../model/form/form-element/form-number";
import { FormOption } from "../model/form/form-element/form-option";
import { FormPhone } from "../model/form/form-element/form-phone";
import { FormPhones } from "../model/form/form-element/form-phones";
import { FormPlaces } from "../model/form/form-element/form-places";
import { FormRadio } from "../model/form/form-element/form-radio";
import { FormTable } from "../model/form/form-element/form-table";
import { FormText } from "../model/form/form-element/form-text";
import { FormTextarea } from "../model/form/form-element/form-textarea";
import { FormTextbox } from "../model/form/form-element/form-textbox";
import { FormUnion } from "../model/form/form-element/form-union";
import { FormVideoupload } from "../model/form/form-element/form-videoupload";
import { FormWeight } from "../model/form/form-element/form-weight";
import { FormModule } from "../model/form/form-module";
import { TranslatableLabel } from "../services/data-catalog.service";

@Injectable({
    providedIn: "root",
})
export class ModuleMapperService {
    private _mapperStrategy = new Map<string, (formElementDto: FormElementDTO) => FormElement>();

    constructor(private db: AngularFirestore) {
        this._mapperStrategy.set("textbox", (fe) => this.mapTextbox(fe));
        this._mapperStrategy.set("text", (fe) => this.mapText(fe));
        this._mapperStrategy.set("radio", (fe) => this.mapRadio(fe));
        this._mapperStrategy.set("datetime", (fe) => this.mapDatetime(fe));
        this._mapperStrategy.set("number", (fe) => this.mapNumber(fe));
        this._mapperStrategy.set("dropdown", (fe) => this.mapDropdown(fe));
        this._mapperStrategy.set("union", (fe) => this.mapUnion(fe));
        this._mapperStrategy.set("checkbox", (fe) => this.mapCheckbox(fe));
        this._mapperStrategy.set("textarea", (fe) => this.mapTextarea(fe));
        this._mapperStrategy.set("imageupload", (fe) => this.mapImageupload(fe));
        this._mapperStrategy.set("videoupload", (fe) => this.mapVideoupload(fe));
        this._mapperStrategy.set("fileupload", (fe) => this.mapFileupload(fe));
        this._mapperStrategy.set("boolean", (fe) => this.mapBoolean(fe));
        this._mapperStrategy.set("table", (fe) => this.mapTable(fe));
        this._mapperStrategy.set("weight", (fe) => this.mapWeight(fe));
        this._mapperStrategy.set("height", (fe) => this.mapHeight(fe));
        this._mapperStrategy.set("phone", (fe) => this.mapPhone(fe));
        this._mapperStrategy.set("phones", (fe) => this.mapPhones(fe));
        this._mapperStrategy.set("places", (fe) => this.mapPlaces(fe));
        this._mapperStrategy.set("cars", (fe) => this.mapCars(fe));
        this._mapperStrategy.set("equipments", (fe) => this.mapEquipments(fe));
        this._mapperStrategy.set("animals", (fe) => this.mapAnimals(fe));
        this._mapperStrategy.set("languages", (fe) => this.mapLanguages(fe));
        this._mapperStrategy.set("availability", (fe) => this.mapAvailability(fe));
    }

    moduleToModuleDto(module: FormModule): ModuleDTO {
        return {
            id: module.id,
            name: module.name,
            order: module.order,
            optional: module.optional,
            formElements: module.formElements.map((fe) => {
                const feDto = fe.toDto();
                return feDto;
            }),
            deleted: module.deleted,
            isConscentModule: module.isConscentModule,
            isMandatoryValidationModule: module.isMandatoryValidationModule
        };
    }

    moduleDtoToModule(moduleDto: ModuleDTO): FormModule {
        return new FormModule({
            id: moduleDto.id,
            name: this.mapName(moduleDto.name),
            order: moduleDto.order,
            optional: moduleDto.optional,
            formElements: this.buildFormElements(moduleDto.formElements),
            deleted: moduleDto.deleted,
            isConscentModule: moduleDto.isConscentModule,
            isMandatoryValidationModule: moduleDto.isMandatoryValidationModule
        });
    }

    private mapName(name: string | TranslatableLabel): TranslatableLabel {
        if (typeof name === "string") {
            return {
                fr: name,
            };
        }
        return name;
    }

    private buildFormElements(moduleDtos: FormElementDTO[]): FormElement[] {
        return moduleDtos
            .map((feDto) => {
                return this.formElementDtoToFormElement(feDto);
            })
            .filter((fe) => fe);
    }

    formElementDtoToFormElement(formElementDto: FormElementDTO): FormElement {
        const mapper = this._mapperStrategy.get(formElementDto.type);

        formElementDto = this.backwardCompatibleFormElement(formElementDto);

        if (mapper) {
            return mapper(formElementDto);
        } else {
            console.info(`Need to map new input type (${formElementDto.type}) for form`);
            return;
        }
    }

    private backwardCompatibleFormElement(formElementDto: any): FormElementDTO {
        if (formElementDto.hasOwnProperty("label") && typeof formElementDto.label === "string") {
            formElementDto.label = { fr: formElementDto.label };
        }
        if (formElementDto.hasOwnProperty("nameDescriptor") && typeof formElementDto.nameDescriptor === "string") {
            formElementDto.nameDescriptor = { fr: formElementDto.nameDescriptor };
        }
        if (formElementDto.hasOwnProperty("description") && typeof formElementDto.description === "string") {
            formElementDto.description = { fr: formElementDto.description };
        }
        if (formElementDto.hasOwnProperty("text") && typeof formElementDto.text === "string") {
            formElementDto.text = { fr: formElementDto.text };
        }

        return formElementDto;
    }

    private mapTextbox(formElementDto: FormElementDTO): FormTextbox {
        return new FormTextbox(formElementDto);
    }
    private mapText(formElementDto: FormElementDTO): FormText {
        return new FormText(formElementDto);
    }
    private mapRadio(formElementDto: FormElementDTO): FormRadio {
        formElementDto.options = this.buildRadioOptions(formElementDto.options);
        return new FormRadio(formElementDto);
    }
    private mapDatetime(formElementDto: FormElementDTO): FormDatetime {
        return new FormDatetime(formElementDto);
    }
    private mapNumber(formElementDto: FormElementDTO): FormNumber {
        return new FormNumber(formElementDto);
    }
    private mapDropdown(formElementDto: FormElementDTO): FormDropdown {
        formElementDto.options = this.buildDropdownOptions(formElementDto.options);
        return new FormDropdown(formElementDto);
    }
    private mapUnion(formElementDto: FormElementDTO): FormUnion {
        return new FormUnion(formElementDto);
    }
    private mapBoolean(formElementDto: FormElementDTO) {
        return new FormBoolean(formElementDto);
    }
    private mapCheckbox(formElementDto: FormElementDTO): FormCheckbox {
        formElementDto.options = this.buildCheckboxesOptions(formElementDto.options);
        return new FormCheckbox(formElementDto);
    }
    private mapTextarea(formElementDto: FormElementDTO): FormTextarea {
        return new FormTextarea(formElementDto);
    }
    private mapFileupload(formElementDto: FormElementDTO): FormFileupload {
        return new FormFileupload(formElementDto);
    }
    private mapImageupload(formElementDto: FormElementDTO): FormImageupload {
        return new FormImageupload(formElementDto);
    }
    private mapVideoupload(formElementDto: FormElementDTO): FormVideoupload {
        return new FormVideoupload(formElementDto);
    }
    private mapTable(formElementDto: FormElementDTO): FormTable {
        return new FormTable(formElementDto);
    }
    private mapWeight(formElementDto: FormElementDTO): FormWeight {
        return new FormWeight(formElementDto);
    }
    private mapHeight(formElementDto: FormElementDTO): FormHeight {
        return new FormHeight(formElementDto);
    }
    private mapPhone(formElementDto: FormElementDTO): FormPhone {
        return new FormPhone(formElementDto);
    }
    private mapPhones(formElementDto: FormElementDTO): FormPhones {
        return new FormPhones(formElementDto);
    }
    private mapPlaces(formElementDto: FormElementDTO): FormPlaces {
        return new FormPlaces(formElementDto);
    }
    private mapCars(formElementDto: FormElementDTO): FormCars {
        return new FormCars(formElementDto);
    }
    private mapEquipments(formElementDto: FormElementDTO): FormEquipments {
        return new FormEquipments(formElementDto);
    }
    private mapAnimals(formElementDto: FormElementDTO): FormAnimals {
        return new FormAnimals(formElementDto);
    }
    private mapLanguages(formElementDto: FormElementDTO): FormLanguages {
        return new FormLanguages(formElementDto);
    }


    private mapAvailability(formElementDto: FormElementDTO): FormAvailability {
        formElementDto = this.mapFormOptionValuesToDate(formElementDto);
        return new FormAvailability(formElementDto);
    }

    private mapFormOptionValuesToDate(formElementDto: FormElementDTO): FormElementDTO {
        if (formElementDto.options) {
            formElementDto.options = formElementDto.options.map((option) => {
                if (option.value) {
                    if (option.value.from && this.valueIsTimestamp(option.value.from)) {
                        option.value.from = option.value.from.toDate();
                    }
                    if (option.value.to && this.valueIsTimestamp(option.value.to)) {
                        option.value.to = option.value.to.toDate();
                    }
                }
                return option;
            });
        }
        return { ...formElementDto };
    }
    private valueIsTimestamp(value: any): boolean {
        return (
            value && typeof value === "object" && value.hasOwnProperty("seconds") && value.hasOwnProperty("nanoseconds")
        );
    }

    private buildCheckboxesOptions(options: string[] | FormOption[]): FormOption[] {
        if (options && Array.isArray(options) && (options as any[]).every((i) => typeof i === "string")) {
            return options.map((option) => {
                return { id: option, label: { fr: option } };
            });
        }
        return (options as FormOption[]) || [];
    }

    private buildRadioOptions(options: string[] | FormOption[]): FormOption[] {
        if (options && Array.isArray(options) && (options as any[]).every((i) => typeof i === "string")) {
            return options.map((option) => {
                return { id: option, label: { fr: option } };
            });
        }
        return (options as FormOption[]) || [];
    }

    private buildDropdownOptions(options: { id: string; name: string; label: string }[] | FormOption[]): FormOption[] {
        if (options && Array.isArray(options) && (options as any[]).every((i) => i.hasOwnProperty("name"))) {
            return (options as { id: string; name: string; label: string }[]).map(
                (option: { id: string; name: string; label: string }) => {
                    return { id: option.name, label: { fr: option.label } };
                }
            );
        }
        return (options as FormOption[]) || [];
    }
}

export interface ModuleDTO {
    id?: string;
    name?: any;
    order?: number;
    optional?: boolean;
    formElements?: FormElementDTO[];
    deleted?: boolean;
    isConscentModule?: boolean;
    isMandatoryValidationModule?: boolean;
}

export interface FilterDTO {
    id: string;
    label?: string;
    positiveTags?: FilterTagDTO[];
    negativeTags?: FilterTagDTO[];
    yesModuleRefs: string[];
    noModuleRefs: string[];
    yesBucket?: string;
    noBucket?: string;
}

export interface FilterTagDTO {
    name?: string;
}

export interface FormElementDTO {
    id?: string;
    name?: string;
    nameDisabled?: boolean;
    nameDescriptor?: TranslatableLabel;
    type?: FormElementType;
    label?: TranslatableLabel;
    defaultValue?: string;
    options?: FormOption[];
    availabilityType?: AvailabilityType;
    hint?: string;
    description?: TranslatableLabel;
    size?: number;
    readonly?: boolean;
    required?: boolean;
    selected?: string;
    score?: number;
    text?: any;
    fields?: any[];
    deleted?: boolean;
}
