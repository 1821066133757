<div [class.no-label]="!options.showLabel" class="clr-form-control" *ngIf="formElement">
    <label *ngIf="options.showLabel" class="clr-control-label">
        {{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>

    <div class="languages">
        <div *ngFor="let language of languagesArray(); index as i" class="clr-control-container language">
            <input
                (change)="onInputChange()"
                class="clr-input"
                type="text"
                [placeholder]="label('languageName')"
                [(ngModel)]="language.name"
            />
            <div class="checkbox">
                <input (change)="onInputChange()" clrCheckbox type="checkbox" [(ngModel)]="language.nativeLanguage" />
                <small>{{ label("nativeLanguage") }}</small>
            </div>

            <select (change)="onInputChange()" [(ngModel)]="language.level">
                <option value="">{{ label("languageLevel") }}</option>
                <option value="elementary">{{ label("languageLevel.elementary") }}</option>
                <option value="intermediate">{{ label("languageLevel.intermediate") }}</option>
                <option value="advanced">{{ label("languageLevel.advanced") }}</option>
            </select>

            <input
                (change)="onInputChange()"
                class="clr-input"
                type="text"
                [placeholder]="label('languageNotes')"
                [(ngModel)]="language.notes"
            />

            <div class="language-actions">
                <cds-icon *ngIf="value && value.length > 1" (click)="removeLanguage(language)" shape="times"></cds-icon>
            </div>
        </div>

        <clr-progress-bar *ngIf="uploadingImages" [clrValue]="percentage" clrMax="100"></clr-progress-bar>

        <a class="action-link" (click)="addLanguage()">{{ label("addLanguge") }}</a>
    </div>
</div>
