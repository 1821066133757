import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchDefinitionMapperService } from 'src/modules/diversite/mappers/search-definition-mapper.service';
import { ContextData } from 'src/modules/diversite/model/context-node';
import { ActionType, Pane, PaneType } from 'src/modules/diversite/model/pane';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { AttributeValue } from 'src/modules/fillout/services/recruit.service';

import { SPLIT_SETTINGS } from './pane-reference.service';

@Injectable({
    providedIn: "root",
})
export class PaneService {
    constructor(private searchDefinitionMapper: SearchDefinitionMapperService, private db: AngularFirestore) { }

    pane(id: string, options?: { listen: boolean }): Observable<Pane> {
        if (options && options.listen === false) {
            return this.db
                .collection("panes")
                .doc(id)
                .get()
                .pipe(
                    map((data) => {
                        return new Pane(this.paneDtoToPane({ ...(data.data() as any), id }));
                    })
                );
        } else {
            return this.db
                .collection("panes")
                .doc(id)
                .valueChanges()
                .pipe(
                    map((data: any) => {
                        return new Pane(this.paneDtoToPane({ ...data, id }));
                    })
                );
        }
    }

    panesForNode(nodeId: string): Observable<Pane[]> {
        return this.db
            .collection("panes", (ref) => {
                return ref.where("contextNodeId", "==", nodeId);
            })
            .get()
            .pipe(
                map((docs) => {
                    return docs.docs.map((doc) => {
                        return new Pane(this.paneDtoToPane({ ...(doc.data() as any), id: doc.id }));
                    });
                })
            );
    }

    addPane(pane: Pane): Observable<Pane> {
        if (!pane.id) {
            pane = pane.change({ id: this.db.createId() });
        }

        const paneDto = this.paneToPaneDTO(pane);
        return from(this.db.collection("panes").doc(pane.id).set(paneDto)).pipe(
            map((_) => {
                return pane;
            })
        );
    }

    removeContactId(pane: Pane, contactId: string): Observable<Pane> {
        return this.editPane(
            pane.change({
                contextData: {
                    ...pane.contextData,
                    contactIds: pane.contextData.contactIds.filter((cid) => cid !== contactId),
                },
            })
        );
    }

    editPane(pane: Pane): Observable<Pane> {
        const paneDto = this.paneToPaneDTO(pane);
        return from(this.db.collection("panes").doc(pane.id).set(paneDto)).pipe(map((_) => pane));
    }

    removePane(paneId: string): Observable<void> {
        return from(this.db.collection("panes").doc(paneId).delete());
    }

    paneToPaneDTO(pane: Pane): PaneDTO {
        return {
            id: pane.id,
            type: pane.type,
            locked: pane.locked,
            projectId: pane.projectId,
            contextData: this.paneContextDataDTO(pane.contextData) || null,
            contextNodeId: pane.contextNodeId,
        };
    }

    private paneContextDataDTO(contextData: PaneContextData): any {
        if (contextData) {

            return {
                ...contextData,
                searchDefinition: contextData.searchDefinition ? this.searchDefinitionMapper.searchDefinitionToSearchDefinitionDTO(contextData.searchDefinition) : null
            }
        }
        return null;
    }

    paneDtoToPane(paneDto: PaneDTO): Pane {


        return new Pane({
            ...paneDto,
            contextData: this.paneContextData(paneDto.contextData)
        });
    }

    private paneContextData(contextDataDto: any): PaneContextData {
        if (contextDataDto) {
            return {
                ...contextDataDto,
                searchDefinition: contextDataDto.searchDefinition ? this.searchDefinitionMapper.searchDefinitionDTOToSearchDefinition(contextDataDto.searchDefinition) : null
            }
        }
        return null;
    }

    defaultPaneMinSizeForType(paneType: PaneType, contextData?: ContextData): number {
        switch (paneType) {
            case "treeview":
                return 600;
            case "form-preview":
                return 500;
            case "contact":
                return 1000;
            case "contacts":
                return 600;
            case "form-edit":
                return 1000;
            case "report-edit":
                return 1200;
            case "search":
                return 1200;
            case "action":
                if (contextData) {
                    if (contextData.actionType) {
                        return 200;
                    }
                }
                return 700;
            case "view-respondents":
                return 1000;
            default:
                return SPLIT_SETTINGS.paneMinSize;
        }
    }
}

export interface PaneDTO {
    id?: string;
    type?: PaneType;
    locked?: boolean;
    projectId: string;
    contextNodeId?: string;
    contextData?: ContextData;
}


export interface PaneContextData {
    contextNodeId?: string;
    searchDefinition?: SearchDefinition;
    searchContextId?: string;
    classificationId?: string;
    classificationGroupId?: string;
    contactCardWidth?: number;
    formId?: string;
    formFieldsToHighlight?: string[];
    packageId?: string;
    focusProjectContextNodeId?: string;
    projectId?: string;
    reportIds?: string[];
    contactIdfigs?: string[];
    contactIds?: string[];
    viewOption?: string;
    contactId?: string;
    formIds?: string[];
    reportId?: string;
    areaSizes?: number[];
    paneId?: string;
    actionType?: ActionType;
    contactsDatabaseId?: string;
    datasource?: string;
    tabOpen?: string;
    attributeValues?: AttributeValue[];
}