<div *ngIf="cardActivities$ | async as cardActivities" class="card-activities">
    <div class="card-activity">
        <button (click)="onOpenDescription()" class="btn btn-sm">Profil</button>
    </div>
    <ng-container *ngIf="cardActivities.length > 0">
        <div class="card-activity" *ngFor="let cardActivity of cardActivities">
            <div class="info">
                <button
                    class="btn btn-sm"
                    (click)="openFormResponse(cardActivity.form.id, cardActivity.deltaAttributeIds)"
                >
                    {{ cardActivity.form?.adminName }}
                </button>
                <small>{{ cardActivity.date | date }}</small>
            </div>
            <div class="actions">
                <button class="btn btn-icon btn-sm">
                    <cds-icon
                        (click)="openFormResponse(cardActivity.form.id, cardActivity.deltaAttributeIds)"
                        shape="search"
                    />
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="cardActivities.length === 0"
        ><br /><em><small>Ce membre a répondu à aucun formulaire</small></em></ng-container
    >
</div>
