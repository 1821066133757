import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { PublicContext } from 'src/modules/core/model/context';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Form } from 'src/modules/diversite/model/form/form';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ContactPublicMapperService } from 'src/modules/fillout/mapper/contact-public-mapper.service';
import { SubmissionReponses } from 'src/modules/fillout/model/submission-responses';
import { ContactPublic, RecruitService } from 'src/modules/fillout/services/recruit.service';
import { SubmissionService } from 'src/modules/fillout/services/submission.service';

@Component({
    selector: "castlug-public-context-container",
    templateUrl: "./public-context-container.component.html",
    styleUrls: ["./public-context-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicContextContainerComponent implements OnInit {
    isSubmitted = false;
    form$: Observable<Form>;
    contact: ContactPublic;
    idfig: string;
    loading = true;
    notFound = false;

    private origin: string;
    context$: Observable<PublicContext>;


    emailUploadPhoto$: Observable<string>;
    private formId: string;

    private disposeBag = new DisposeBag();

    constructor(
        private route: ActivatedRoute,
        private formService: FormService,
        private recruitService: RecruitService,
        private contactService: ContactService,
        private submissionService: SubmissionService,
        private contactPublicMapper: ContactPublicMapperService,
        private router: Router,
        private chRef: ChangeDetectorRef,
        private db: AngularFirestore
    ) { }

    ngOnInit(): void {
        this.emailUploadPhoto$ = this.route.queryParamMap.pipe(map(params => params.get("unregisteredEmail")));

        this.context$ = this.route.data.pipe(
            map((param: Data) => {
                return param.context as PublicContext
            }),
            tap(context => {
                this.notFound = false;
                this.formId = undefined;
                this.loading = true;

                if (context) {
                    const queryParamEmail = this.route.snapshot.queryParams.unregisteredEmail;
                    if (context.type === "upload-images" && queryParamEmail) {
                        this.loading = false;
                    } else if (context.disabled) {
                        this.loading = false;
                        this.notFound = false;
                    }
                    this.origin = `${window.location.origin}/#`;
                    this.chRef.detectChanges();
                } else {
                    this.loading = false;
                    this.notFound = true;
                    this.chRef.detectChanges();
                }
            }));

        this.form$ = this.context$.pipe(switchMap((context: PublicContext) => {
            if (context) {
                if (!context.disabled && context.type === "recruitment") {
                    return this.formService.form(context.form.id, { listen: false }).pipe(
                        map((form) => {
                            if (form) {
                                this.formId = form.id;
                                this.loading = false;
                                return form;
                            } else {
                                this.loading = false;
                                this.notFound = true;
                                this.chRef.detectChanges();
                                return undefined;
                            }
                        })
                    );
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
                this.notFound = true;
                this.chRef.detectChanges();
                return of(undefined);
            }
        }));


        const randomId = this.route.snapshot.queryParams.c;
        if (randomId) {
            this.recruitService.getContactForRandomId(randomId).subscribe((data) => {
                if (data && data.contact && data.idfig) {
                    this.idfig = data.idfig;
                    this.contact = data.contact;
                    this.emailUploadPhoto$ = of(this.contact.attributes.find(attr => attr.index === "contactInformation_email")?.value)
                } else {
                    this.router.navigate(["./"], { relativeTo: this.route, queryParams: {} });
                }
                this.loading = false;
                this.chRef.detectChanges();
            }).disposedBy(this.disposeBag);
        }

        /*Fix panique Julie pour generer lien avec contactId*/
        const contactId = this.route.snapshot.queryParams.contactId;
        if (contactId) {
            this.contactService.contactById(contactId).subscribe((contact) => {
                if (contact && contact.idfig) {
                    const contactPublic: ContactPublic = this.contactPublicMapper.mapContactToContactPublic(contact);
                    this.contact = contactPublic;
                    this.idfig = contact.idfig;
                    this.loading = false;
                    this.emailUploadPhoto$ = of(this.contact.attributes.find(attr => attr.index === "contactInformation_email")?.value)
                    this.chRef.markForCheck();
                }
            }).disposedBy(this.disposeBag);
        }

        // fix load contact from legacy link
        const lnk = this.route.snapshot.queryParams.lnk;
        if (lnk) {
            this.recruitService.getContactForLegacyLinkLnk(lnk).subscribe((data) => {
                if (data && data.contact && data.idfig) {
                    this.idfig = data.idfig;
                    this.contact = data.contact;
                    this.emailUploadPhoto$ = of(this.contact.attributes.find(attr => attr.index === "contactInformation_email")?.value)
                } else {
                    this.router.navigate(["./"], { relativeTo: this.route, queryParams: {} });
                }
                this.loading = false;
                this.chRef.detectChanges();
            }).disposedBy(this.disposeBag);
        }
    }

    onSubmitForm(event: SubmissionReponses): void {
        if (event.contact) {
            this.contact = event.contact || null;
        }
        if (event.idfig) {
            this.idfig = event.idfig || null;
        }
        const queryP = this.route.snapshot?.queryParams || {};
        if (this.isExistingContact()) {
            this.submissionService
                .existingContactSubmitForm(
                    {
                        form: event.form,
                        responseInputs: event.responseInputs,
                    },
                    this.idfig,
                    this.formId,
                    this.origin,
                    queryP
                )
                .subscribe((_) => {
                    this.isSubmitted = true;
                    this.chRef.detectChanges();
                }).disposedBy(this.disposeBag);
        } else {
            this.submissionService
                .publicSubmitForm(
                    {
                        form: event.form,
                        responseInputs: event.responseInputs,
                    },
                    this.formId,
                    this.origin,
                    queryP
                )
                .subscribe((_) => {
                    this.isSubmitted = true;
                    this.chRef.detectChanges();
                }).disposedBy(this.disposeBag);
        }
    }

    onSubmitPhoto(event: SubmissionReponses): void {
        const queryP = this.route.snapshot?.queryParams || {};
        if (event.idfig) {
            this.submissionService.existingContactSubmitForm(
                {
                    responseInputs: event.responseInputs,
                },
                event.idfig,
                null,
                this.origin, queryP,
                { type: "upload-photo" }
            )
                .subscribe((_) => {
                    this.isSubmitted = true;
                    this.chRef.detectChanges();
                }).disposedBy(this.disposeBag);
        } else {
            this.submissionService.publicSubmitForm(
                {
                    responseInputs: event.responseInputs,
                },
                this.formId,
                this.origin,
                queryP,
                { type: "upload-photo" }
            )
                .subscribe((_) => {
                    this.isSubmitted = true;
                    this.chRef.detectChanges();
                }).disposedBy(this.disposeBag);
        }
    }

    disabled(context?: PublicContext) {
        if (context) {
            return context.disabled
        }
        return false;
    }

    isExistingContact(): boolean {
        return this.contact && this.idfig ? true : false;
    }

    get hideEmailBox(): boolean {
        return this.contact ? true : false;
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
