import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ClrCombobox } from '@clr/angular';
import { BehaviorSubject, catchError, debounceTime, delay, filter, finalize, Observable, skip, take, tap, throwError } from 'rxjs';
import { guid } from 'src/app/core/functions';
import { FileUploadImageService } from 'src/modules/core/services/file-upload-image.service';
import { UploadFilesResponse } from 'src/modules/core/services/file-upload.service';
import { CarBrand, StaticDataService } from 'src/modules/core/services/static-data.service';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Car } from 'src/modules/diversite/model/car';
import { FormCars } from 'src/modules/diversite/model/form/form-element/form-cars';
import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: 'fillout-cars',
    templateUrl: './cars.component.html',
    styleUrl: './cars.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarsComponent {
    private translationId: string = "Jis8Crd0PMO3yNyGcqmA";
    @Input() lang: string;
    @Input() formElement: FormCars;
    @Input() value: Car[] = [];
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<Car[]>();

    @ViewChild("combobox", { read: ClrCombobox }) combobox: ClrCombobox<string[]>;

    private translationLabels: StaticLabels = {};
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    cars: { [key: string]: Car } = {};
    carsLoading = false;

    uploadingImages = false;
    percentage: number = 0;


    searchCarChange$ = new BehaviorSubject<string>(undefined);
    availableCarBrands$: Observable<CarBrand[]>;

    private disposeBag = new DisposeBag();


    constructor(
        private fileUploadService: FileUploadImageService,
        private staticDataService: StaticDataService,
        private staticLabelsService: StaticLabelsService,
        private sanitizer: DomSanitizer,
        private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.availableCarBrands$ = this.staticDataService.carBrands()
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe(() => {
                this.responseChange.emit(Object.values(this.cars));
            })
            .disposedBy(this.disposeBag);


        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setEditableModel(changes.value.currentValue)
    }

    private setEditableModel(value: Car[]): void {
        this.cars = {}
        if (value && value.length > 0) {
            value.forEach((element, index) => {
                this.cars[index] = element
            });
        } else {
            this.cars = { 0: { id: guid(), brand: null, model: "", notes: "", pictures: [], year: null, color: "" } };
        }
    }

    onFileSelected(event: any, carIndex: number, retry = false): void {
        if (!this.formElement.readonly) {
            if (event.target.files.length > 0) {
                this.uploadingImages = true;
                this.percentage = 0;
                this.fileUploadService
                    .uploadFiles(Array.from(event.target.files))
                    .pipe(
                        tap((uploadVideoResponse: UploadFilesResponse) => {
                            this.percentage = uploadVideoResponse.percentage;
                            this.chRef.detectChanges();
                        }),
                        filter((uploadVideoResponse: UploadFilesResponse) => {
                            return uploadVideoResponse.percentage === 100;
                        }),
                        take(1),
                        delay(500),
                        catchError((err) => {
                            if (!retry) {
                                this.onFileSelected(event, carIndex, true);
                                return null;
                            }
                            return throwError(err);
                        }),
                        finalize(() => { })
                    )
                    .subscribe((uploadVideoResponse) => {
                        // this.imageUrls = [...this.imageUrls, ...uploadVideoResponse.urls];
                        // this.responseChange.emit(this.imageUrls);
                        this.cars[carIndex].pictures = [...this.cars[carIndex].pictures, ...uploadVideoResponse.urls];
                        this.percentage = 0;
                        this.uploadingImages = false;
                        this.onInputChange();
                        this.chRef.detectChanges();
                    })
                    .disposedBy(this.disposeBag);
            }
        }
    }

    trackByUrl(_: number, url: string): string {
        return url;
    }

    carsArray(): Car[] {
        return Object.values(this.cars);
    }

    backgroundImage(imageUrl: string) {
        if (imageUrl) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
        }
    }


    deleteImage(carIndex: number, carImg: string): void {
        this.cars[carIndex].pictures = this.cars[carIndex].pictures.filter(cImg => cImg !== carImg);
        this.onInputChange();
    }

    onInputChange(): void {
        this.delayedChanges$.next();
    }


    trackById(_: number, entity: any): string {
        return entity.id;
    }

    label(labelId: string): string {
        if (
            this.translationLabels &&
            this.translationLabels[labelId] &&
            this.translationLabels[labelId][this.lang]
        ) {
            return this.translationLabels[labelId][this.lang];
        }
        return labelId;
    }

    addCar(): void {
        if (this.value && this.value.length > 0) {
            this.value = [...this.value, { id: guid(), brand: null, model: "", notes: "", pictures: [], year: null, color: "" }];
        } else {
            this.value = [this.cars[0], { id: guid(), brand: null, model: "", notes: "", pictures: [], year: null, color: "" }];
        }
        this.setEditableModel(this.value);
        this.onInputChange();
        this.chRef.detectChanges();
    }

    removeCar(car: Car): void {
        this.value = this.value.filter((p) => p.id !== car.id);
        this.setEditableModel(this.value);
        this.onInputChange()
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
