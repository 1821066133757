import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ClrCombobox } from '@clr/angular';
import { BehaviorSubject, catchError, debounceTime, delay, filter, finalize, skip, take, tap, throwError } from 'rxjs';
import { guid } from 'src/app/core/functions';
import { FileUploadImageService } from 'src/modules/core/services/file-upload-image.service';
import { UploadFilesResponse } from 'src/modules/core/services/file-upload.service';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Equipment } from 'src/modules/diversite/model/equipment';
import { FormEquipments } from 'src/modules/diversite/model/form/form-element/form-equipments';
import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: 'fillout-equipments',
    templateUrl: './equipments.component.html',
    styleUrl: './equipments.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentsComponent {
    private translationId: string = "pDLlBvhGeMbjMmgAbPUq";
    @Input() lang: string;
    @Input() formElement: FormEquipments;
    @Input() value: Equipment[] = [];
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<Equipment[]>();

    @ViewChild("combobox", { read: ClrCombobox }) combobox: ClrCombobox<string[]>;

    private translationLabels: StaticLabels = {};
    delayedChanges$ = new BehaviorSubject<void>(undefined);


    equipments: { [key: string]: Equipment } = {};


    uploadingImages = false;
    percentage: number = 0;



    private disposeBag = new DisposeBag();


    constructor(
        private fileUploadService: FileUploadImageService,
        private staticLabelsService: StaticLabelsService,
        private sanitizer: DomSanitizer,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe(() => {
                this.responseChange.emit(Object.values(this.equipments));
            })
            .disposedBy(this.disposeBag);


        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setEditableModel(changes.value.currentValue)
    }

    private setEditableModel(value: Equipment[]): void {
        this.equipments = {}
        if (value && value.length > 0) {
            value.forEach((element, index) => {
                this.equipments[index] = element;
            });
        } else {
            this.equipments = { 0: { id: guid(), name: "", description: "", pictures: [] } };
        }
    }

    onFileSelected(event: any, equipmentIndex: number, retry = false): void {
        if (!this.formElement.readonly) {
            if (event.target.files.length > 0) {
                this.uploadingImages = true;
                this.percentage = 0;
                this.fileUploadService
                    .uploadFiles(Array.from(event.target.files))
                    .pipe(
                        tap((uploadVideoResponse: UploadFilesResponse) => {
                            this.percentage = uploadVideoResponse.percentage;
                            this.chRef.detectChanges();
                        }),
                        filter((uploadVideoResponse: UploadFilesResponse) => {
                            return uploadVideoResponse.percentage === 100;
                        }),
                        take(1),
                        delay(500),
                        catchError((err) => {
                            if (!retry) {
                                this.onFileSelected(event, equipmentIndex, true);
                                return null;
                            }
                            return throwError(err);
                        }),
                        finalize(() => { })
                    )
                    .subscribe((uploadVideoResponse) => {

                        this.equipments[equipmentIndex].pictures = [...this.equipments[equipmentIndex].pictures, ...uploadVideoResponse.urls];
                        this.percentage = 0;
                        this.uploadingImages = false;
                        this.onInputChange();
                        this.chRef.detectChanges();
                    })
                    .disposedBy(this.disposeBag);
            }
        }
    }

    trackByUrl(_: number, url: string): string {
        return url;
    }

    equipmentsArray(): Equipment[] {
        return Object.values(this.equipments);
    }

    backgroundImage(imageUrl: string) {
        if (imageUrl) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
        }
    }


    deleteImage(carIndex: number, carImg: string): void {
        this.equipments[carIndex].pictures = this.equipments[carIndex].pictures.filter(cImg => cImg !== carImg);
        this.onInputChange();
    }

    onInputChange(): void {
        this.delayedChanges$.next();
    }


    trackById(_: number, entity: any): string {
        return entity.id;
    }

    label(labelId: string): string {
        if (
            this.translationLabels &&
            this.translationLabels[labelId] &&
            this.translationLabels[labelId][this.lang]
        ) {
            return this.translationLabels[labelId][this.lang];
        }
        return labelId;
    }

    addEquipment(): void {
        if (this.value && this.value.length > 0) {
            this.value = [...this.value, { id: guid(), name: "", description: "", pictures: [] }];
        } else {
            this.value = [this.equipments[0], { id: guid(), name: "", description: "", pictures: [] }];
        }
        this.setEditableModel(this.value);
        this.onInputChange();
        this.chRef.detectChanges();
    }

    removeEquipment(equipment: Equipment): void {
        this.value = this.value.filter((p) => p.id !== equipment.id);
        this.setEditableModel(this.value);
        this.onInputChange()
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
