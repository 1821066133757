<div class="form-request-container" [class.preview]="mode === 'preview'" *ngIf="form">
    <div *ngIf="!isAdmin" class="branding">
        <img class="main-logo-castlug" [src]="logo()" />
    </div>

    <div class="is-submitting" *ngIf="isSubmitting && !isSubmitted">
        <span class="spinner spinner-lg"> {{ label("submittingWaitingLabel") }} </span>
    </div>

    <div *ngIf="!isSubmitted && !isSubmitting && !isEmailSubmitted && form.languages.length > 1" class="lang">
        <span (click)="selectLang(lang)" [class.current]="lang === selectedLang" *ngFor="let lang of form.languages">{{
            lang
        }}</span>
    </div>

    <div *ngIf="!isSubmitting && !isSubmitted && !hideEmailBox && mode !== 'preview'" class="form-invitation-container">
        <div *ngIf="!isEmailSubmitted">
            <form>
                <p>
                    <cds-icon
                        (click)="toggleDoWeKnowBox()"
                        shape="angle"
                        [direction]="doWeKnowYouBoxOpen ? 'down' : 'right'"
                    >
                    </cds-icon
                    >&nbsp;{{ label("doWeKnowYouText") }}
                </p>
                <div *ngIf="doWeKnowYouBoxOpen">
                    <p>{{ label("chooseIdentificationMethodText") }}</p>

                    <div class="white-box">
                        <clr-radio-wrapper>
                            <label>{{ label("emailLabel") }}</label>
                            <input
                                clrRadio
                                type="radio"
                                [(ngModel)]="knowStrategy"
                                name="know_strategy"
                                value="email"
                            />
                        </clr-radio-wrapper>
                        <clr-input-container>
                            <label></label>
                            <input
                                clrInput
                                [disabled]="disabledKnowStrategy('email')"
                                name="know_strategy_email"
                                [(ngModel)]="email"
                                required
                            />
                        </clr-input-container>
                    </div>
                    <div class="white-box">
                        <clr-radio-wrapper>
                            <label>{{ label("idfig") }}</label>
                            <input
                                clrRadio
                                type="radio"
                                [(ngModel)]="knowStrategy"
                                name="know_strategy"
                                value="idfig"
                            />
                        </clr-radio-wrapper>
                        <div *ngIf="knowStrategy">
                            <clr-input-container>
                                <label></label>
                                <input
                                    clrInput
                                    [disabled]="disabledKnowStrategy('idfig')"
                                    (keyup)="getStrategiesForIdfig($event.target.value)"
                                    name="know_strategy_idfig"
                                    [(ngModel)]="idfig"
                                    required
                                />
                            </clr-input-container>
                            <clr-spinner *ngIf="getCodeOptionsLoading" [clrSmall]="true"> </clr-spinner>
                            <ng-container *ngIf="idfigStrategyOptions$ | async as options">
                                <clr-radio-container
                                    class="strategy-options"
                                    *ngIf="options.length > 0"
                                    layout="vertical"
                                    control-width="shrink"
                                >
                                    <clr-radio-wrapper *ngFor="let option of options">
                                        <label>{{ option.value }}</label>
                                        <input
                                            clrRadio
                                            (change)="changeIdfigStrategy(option.strategy)"
                                            [checked]="idfigStrategy === option.strategy"
                                            [disabled]="disabledKnowStrategy('idfig')"
                                            type="radio"
                                            [value]="option.strategy"
                                        />
                                    </clr-radio-wrapper>
                                </clr-radio-container>
                                <p *ngIf="options.length === 0">
                                    <em>{{ label("noCommunicationMethodHasBeenFoundText") }}</em>
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div id="newMemberEmailInputElement" class="white-box">
                        <clr-radio-wrapper>
                            <label>{{ label("newMemberLabelText") }}</label>
                            <input clrRadio type="radio" [(ngModel)]="knowStrategy" name="know_strategy" value="new" />
                        </clr-radio-wrapper>

                        <clr-input-container>
                            <label></label>
                            <input
                                [disabled]="knowStrategy !== 'new'"
                                clrInput
                                name="newEmail"
                                email
                                type="email"
                                [(ngModel)]="newEmail"
                            />
                        </clr-input-container>
                        <span *ngIf="knowStrategy === 'new'" class="required-star">*</span>
                    </div>

                    <button
                        *ngIf="knowStrategy === 'idfig' || knowStrategy === 'email'"
                        (click)="getCode()"
                        [disabled]="gettingCodeDisabled || getCodeLoading"
                        class="btn btn-primary"
                    >
                        {{ label("getAccessCodeButton") }}&nbsp;
                        <clr-spinner *ngIf="getCodeLoading" [clrSmall]="true"> </clr-spinner>
                    </button>

                    <div class="white-box access-code" *ngIf="showCodeEntryBox">
                        <cds-input layout="vertical" control-width="shrink">
                            <label>{{ label("gotCodeLabel") }}</label>
                            <input
                                (keyup)="onAccessCodeKeyUp($event.target.value)"
                                [(ngModel)]="accessCode"
                                name="accessCode"
                                required
                            />
                            <cds-control-message *ngIf="accessCodeError" status="error">
                                {{ label("wrongCodeError") }}
                            </cds-control-message>
                        </cds-input>
                        <clr-spinner *ngIf="accessCodeLoading" [clrSmall]="true"> </clr-spinner>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="isEmailSubmitted" [innerHTML]="safe(label('thanksTextEmailSubmittedBox'))"></div>
    </div>

    <div [hidden]="!showForm">
        <div class="form">
            <ng-container *ngFor="let module of form.modules; trackBy: trackByFnModules">
                <div *ngIf="!module.deleted" class="module">
                    <div
                        *ngIf="module.name && module.name[selectedLang] && module.name[selectedLang] !== ''"
                        class="module-header"
                    >
                        <h3>
                            {{ module.name[selectedLang] }}
                        </h3>
                    </div>
                    <div
                        [id]="formElement.id"
                        class="form-element-container"
                        *ngFor="let formElement of module.formElements; trackBy: trackByFnFormElement"
                    >
                        <fillout-form-element
                            *ngIf="!formElement.deleted && !isAdminAndHiddenElement(formElement)"
                            [class.highlight]="mode === 'preview' && inputToHighlight?.includes(formElement.name)"
                            [lang]="selectedLang$ | async"
                            [value]="value(formElement)"
                            [formElement]="formElement"
                            (responseChange)="onResponseChange($event)"
                        >
                        </fillout-form-element>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="showErrorsBoard && !isValid()" class="errors alert alert-danger">
                <div class="alert-items">
                    <strong>{{ label("fixFollowingErrorsText") }}</strong>
                    <div class="alert-item static" *ngFor="let errorsInput of errors; trackBy: trackByError">
                        <ng-container *ngIf="errorsInput.length === 1 && errorsInput[0].isNewMemberEmailError">
                            <div class="alert-icon-wrapper">
                                <clr-icon class="alert-icon" shape="exclamation-circle"> </clr-icon>
                            </div>
                            <span class="alert-text"> {{ label("seeNewMemberErrorText") }} </span>
                            <div class="alert-actions">
                                <a (click)="seeNewMemberEmailField()" class="error-focus-link">{{
                                    label("seeErrorText")
                                }}</a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="errorsInput.length === 1 && !errorsInput[0].isNewMemberEmailError">
                            <div class="alert-icon-wrapper">
                                <clr-icon class="alert-icon" shape="exclamation-circle"> </clr-icon>
                            </div>
                            <span class="alert-text">
                                <span *ngIf="errorsInput.length <= 1"
                                    >{{ label("errorRequiredText") }} :
                                    <em>{{ errorsInput[0].formElement.label[selectedLang] }}</em>
                                </span>

                                <ng-container *ngIf="errorsInput[0].type === 'tableFieldsRequired'">
                                    {{ label("errorTableHasInvalidFieldsText") }}
                                </ng-container>
                            </span>
                            <div class="alert-actions">
                                <a (click)="seeError(errorsInput[0])" class="error-focus-link">{{
                                    label("seeErrorText")
                                }}</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <button *ngIf="form" (click)="submit()" class="submit-btn btn btn-primary">
                {{ label("submitButtonLabel") }}
            </button>
        </div>
    </div>

    <div *ngIf="isSubmitted || forceEndText">
        <div [innerHtml]="safe(form.endText[selectedLang])"></div>
    </div>
</div>

<ng-container *ngIf="authenticatedProfiles$ | async as profiles">
    <clr-modal [clrModalOpen]="profiles && chooseProfileModalOpen">
        <h3 class="modal-title">{{ label("yourProfilesModalTitle") }}</h3>
        <div class="modal-body">
            <cds-alert status="info">{{ label("selectProfileToUseInstructionText") }}</cds-alert>
            <cds-divider></cds-divider>
            <br />
            <div class="profiles">
                <clr-spinner *ngIf="loadingContact"> </clr-spinner>
                <div
                    [class.loading]="loadingContact"
                    (click)="loadContact(profile.idfig)"
                    class="profile"
                    *ngFor="let profile of profiles"
                >
                    {{ profile.firstName }} {{ profile.lastName }}
                    <br />
                    {{ profile.idfig }}
                </div>
            </div>
        </div>
    </clr-modal>
</ng-container>
