<div [class.no-label]="!options.showLabel" class="clr-form-control" *ngIf="formElement">
    <label *ngIf="options.showLabel" class="clr-control-label">
        {{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>

    <div class="animals">
        <div *ngFor="let animal of animalsArray(); index as i" class="clr-control-container animal">
            <input
                (change)="onInputChange()"
                class="clr-input name"
                type="text"
                [placeholder]="label('animalName')"
                [(ngModel)]="animal.name"
            />
            <input
                (change)="onInputChange()"
                class="clr-input race"
                type="text"
                [placeholder]="label('animalType')"
                [(ngModel)]="animal.type"
            />
            <input
                (change)="onInputChange()"
                class="clr-input race"
                type="text"
                [placeholder]="label('animalRace')"
                [(ngModel)]="animal.race"
            />
            <select (change)="onInputChange()" [(ngModel)]="animal.sex">
                <option value="">-</option>
                <option value="f">{{ label("animalMale") }}</option>
                <option value="m">{{ label("animalFemale") }}</option>
            </select>
            <input
                (change)="onInputChange()"
                class="clr-input"
                type="text"
                [placeholder]="label('animalDesc')"
                [(ngModel)]="animal.description"
            />

            <div class="clr-file-wrapper">
                <label class="clr-control-label">
                    <span class="btn btn-primary btn-sm">{{ label("addPhotoButtonText") }}</span>
                    <input
                        [id]="formElement.id"
                        (change)="onFileSelected($event, i)"
                        class="clr-file clr-input"
                        type="file"
                        accept="image/*"
                        multiple
                        [name]="formElement.name + i"
                    />
                </label>
            </div>

            <div class="animal-actions">
                <cds-icon *ngIf="value && value.length > 1" (click)="removeAnimal(animal)" shape="times"></cds-icon>
            </div>

            <div class="animal-images">
                <div
                    class="animal-img"
                    *ngFor="let animalImg of animal.pictures; trackBy: trackByUrl"
                    [style.background-image]="backgroundImage(animalImg)"
                >
                    <cds-icon (click)="deleteImage(i, animalImg)" class="delete" shape="times-circle"></cds-icon>
                </div>
            </div>
        </div>

        <clr-progress-bar *ngIf="uploadingImages" [clrValue]="percentage" clrMax="100"></clr-progress-bar>

        <a class="action-link" (click)="addAnimal()">{{ label("addAnimal") }}</a>
    </div>
</div>
