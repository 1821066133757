import { TranslatableLabel } from "../services/data-catalog.service";
import { FormElementType } from "./form/form-element/form-element";
import { FormOption } from "./form/form-element/form-option";

interface Builder {
    attributes: AttributeIndex[];
}

export class ContactProfileDefinition {
    _attributes: AttributeIndex[];

    static EMPTY(): ContactProfileDefinition {
        return new ContactProfileDefinition();
    }

    constructor(builder?: Builder) {
        this._attributes = builder && builder.attributes ? builder.attributes.filter(attr => attr.index ? true : false) : [];
    }

    get attributes(): AttributeIndex[] {
        return this._attributes;
    }
}

export interface AttributeIndex {
    index: string;
    label: TranslatableLabel;
    defaultDescription?: boolean;
    displayed?: boolean;
    module?: string;
    order?: number;
    formField?: AttributeFormField;
}

export interface AttributeFormField {
    type: FormElementType;
    required: boolean;
    options?: FormOption[];
}
