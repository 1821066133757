<div *ngIf="formElement && tempFormElement && tempFormElement.label" class="form-element-edition">
    <div>
        <span
            #labelElement
            class="edit-label"
            contenteditable
            (keyup)="onLabelChange()"
            [innerHTML]="tempFormElement.label[lang]"
        ></span>
        <ng-content select="[tooltip]"></ng-content>
        <div class="inputs">
            <input readonly class="fake-input" type="text" placeholder="Nom" />
            <input readonly class="fake-input" type="text" placeholder="Race" />
            <input readonly class="fake-input" type="text" placeholder="Sex" />
            <input readonly class="fake-input" type="text" placeholder="Description" />
            <button disabled class="btn btn-primary btn-sm">Photo(s)</button>
        </div>
        <a class="action-link disabled">Ajouter un animal</a>
    </div>
</div>
