<div *ngIf="formElement && tempFormElement && tempFormElement.label" class="form-element-edition">
    <div>
        <span
            #labelElement
            class="edit-label"
            contenteditable
            (keyup)="onLabelChange()"
            [innerHTML]="tempFormElement.label[lang]"
        ></span>
        <ng-content select="[tooltip]"></ng-content>
        <div class="inputs">
            <input readonly class="fake-input" type="text" placeholder="Marque" />
            <input readonly class="fake-input" type="text" placeholder="Modèle" />
            <input readonly class="fake-input" type="text" placeholder="Année" />
            <input readonly class="fake-input" type="text" placeholder="Couleur" />
            <input readonly class="fake-input" type="text" placeholder="Notes" />
            <button disabled class="btn btn-primary btn-sm">Photo(s)</button>
        </div>
        <a class="action-link disabled">Ajouter une voiture</a>
    </div>
</div>
