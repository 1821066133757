<div [class.no-label]="!options.showLabel" class="clr-form-control" *ngIf="formElement">
    <label *ngIf="options.showLabel" class="clr-control-label">
        {{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>

    <div class="cars">
        <div *ngFor="let car of carsArray(); index as i" class="clr-control-container car">
            <clr-combobox-container>
                <clr-combobox [(ngModel)]="car.brand" (ngModelChange)="onInputChange()">
                    <clr-options>
                        <clr-option
                            *clrOptionItems="let carBrand of availableCarBrands$ | async; field: 'name'"
                            [clrValue]="carBrand"
                        >
                            {{ carBrand.name }}
                        </clr-option>
                    </clr-options>
                </clr-combobox>
            </clr-combobox-container>

            <input
                (change)="onInputChange()"
                class="clr-input"
                type="text"
                [placeholder]="label('carModel')"
                [(ngModel)]="car.model"
            />
            <input
                (change)="onInputChange()"
                class="clr-input carYear"
                type="number"
                [placeholder]="label('carYear')"
                [(ngModel)]="car.year"
            />
            <input
                (change)="onInputChange()"
                class="clr-input carColor"
                type="text"
                [placeholder]="label('carColor')"
                [(ngModel)]="car.color"
            />
            <input
                (change)="onInputChange()"
                class="clr-input"
                type="text"
                [placeholder]="label('notes')"
                [(ngModel)]="car.notes"
            />

            <div class="clr-file-wrapper">
                <label class="clr-control-label">
                    <span class="btn btn-primary btn-sm">{{ label("addPhotoButtonText") }}</span>
                    <input
                        [id]="formElement.id"
                        (change)="onFileSelected($event, i)"
                        class="clr-file clr-input"
                        type="file"
                        accept="image/*"
                        multiple
                        [name]="formElement.name + i"
                    />
                </label>
            </div>

            <div class="car-actions">
                <cds-icon *ngIf="value && value.length > 1" (click)="removeCar(car)" shape="times"></cds-icon>
            </div>

            <div class="car-images">
                <div
                    class="car-img"
                    *ngFor="let carImg of car.pictures; trackBy: trackByUrl"
                    [style.background-image]="backgroundImage(carImg)"
                >
                    <cds-icon (click)="deleteImage(i, carImg)" class="delete" shape="times-circle"></cds-icon>
                </div>
            </div>
        </div>

        <clr-progress-bar *ngIf="uploadingImages" [clrValue]="percentage" clrMax="100"></clr-progress-bar>

        <a class="action-link" (click)="addCar()">{{ label("addCar") }}</a>
    </div>
</div>
