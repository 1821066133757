import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Observable } from 'rxjs';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { CardActivity, ContactChangeHistoryService } from 'src/modules/diversite/services/contact-change-history.service';
import { ContextNodePaneActionsService } from '../../context-node/context-node-pane-actions.service';

@Component({
    selector: 'diversite-contact-card-activites',
    templateUrl: './contact-card-activites.component.html',
    styleUrl: './contact-card-activites.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardActivitesComponent {
    @Input() contactPerspective: ContactPerspective;
    @Output() openDescription = new EventEmitter<void>();

    cardActivities$: Observable<CardActivity[]>;

    constructor(private contactChangeHistoryService: ContactChangeHistoryService, private actionPaneService: ContextNodePaneActionsService) { }

    ngOnInit(): void {
        this.cardActivities$ = this.contactChangeHistoryService.cardActivities(this.contactPerspective.id);
    }


    openFormResponse(formId: string, attributeIds: string[]): void {
        this.actionPaneService.openFormPane(formId, null, null, this.contactPerspective.id, attributeIds);
    }

    onOpenDescription(): void {
        this.openDescription.emit();
    }
}

