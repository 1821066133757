import { PublicProjectConfiguration } from './public-project-configuration';

export interface ProjectBuilder {
    id?: string;
    name?: string;
    status?: ProjectStatus;
    published?: boolean;
    config?: PublicProjectConfiguration;
    created?: Date;
    archived?: boolean;
    deleted?: boolean;
}
export class Project {
    private _id: string;
    private _name: string;
    private _published: boolean;
    private _config: PublicProjectConfiguration;
    private _status: ProjectStatus;
    private _created: Date;
    private _archived: boolean;
    private _deleted: boolean;

    static get DEFAULT_PROJECT(): Project {
        return new Project({
            name: "Projet",
        });
    }
    constructor(builder: ProjectBuilder) {
        this._id = builder.id;
        this._name = builder.name;
        this._published = builder.published === true ? true : false;
        this._config = builder.config || null;
        this._created = builder.created;
        this._status = builder.status;
        this._archived = builder.archived === true ? true : false;
        this._deleted = builder.deleted === true ? true : false;
    }

    get id(): string {
        return this._id;
    }
    get name(): string {
        return this._name;
    }
    get published(): boolean {
        return this._published;
    }
    get config(): PublicProjectConfiguration {
        return this._config;
    }
    get created(): Date {
        return this._created;
    }
    get archived(): boolean {
        return this._archived;
    }
    get deleted(): boolean {
        return this._deleted;
    }
    get status(): ProjectStatus {
        return this._status;
    }

    restore(): Project {
        return this.change({ archived: false, deleted: false });
    }
    delete(): Project {
        return this.change({ archived: false, deleted: true });
    }

    changeConfig(config: PublicProjectConfiguration): Project {
        return this.change({ config });
    }

    change(builder: ProjectBuilder): Project {
        return new Project({
            id: this._id,
            name: this._name,
            created: this._created,
            status: this._status,
            archived: this._archived,
            deleted: this._deleted,
            published: this._published,
            config: this._config,
            ...builder,
        });
    }
}

export type ProjectStatus = "deleted" | "archived" | "active";

