<div *ngIf="formElement && tempFormElement && tempFormElement.label" class="form-element-edition">
    <div>
        <span
            #labelElement
            class="edit-label"
            contenteditable
            (keyup)="onLabelChange()"
            [innerHTML]="tempFormElement.label[lang]"
        ></span>
        <ng-content select="[tooltip]"></ng-content>
        <div class="inputs">
            <input readonly class="fake-input" type="text" placeholder="Langue" />
            <div class="inputs">
                <input readonly type="checkbox" />
                <small>Langue maternelle</small>
            </div>
            <input readonly class="fake-input" type="text" placeholder="Niveau" />
            <input readonly class="fake-input" type="text" placeholder="Notes" />
        </div>
        <a class="action-link disabled">Ajouter une langue</a>
    </div>
</div>
