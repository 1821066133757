import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClrCombobox } from '@clr/angular';
import { BehaviorSubject, debounceTime, skip, take } from 'rxjs';
import { guid } from 'src/app/core/functions';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormLanguages } from 'src/modules/diversite/model/form/form-element/form-languages';
import { Language } from 'src/modules/diversite/model/language';
import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: 'fillout-languages',
    templateUrl: './languages.component.html',
    styleUrl: './languages.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagesComponent {
    private translationId: string = "Bio0OECcnEO6wGJILcsx";
    @Input() lang: string;
    @Input() formElement: FormLanguages;
    @Input() value: Language[] = [];
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<Language[]>();

    @ViewChild("combobox", { read: ClrCombobox }) combobox: ClrCombobox<string[]>;

    private translationLabels: StaticLabels = {};
    delayedChanges$ = new BehaviorSubject<void>(undefined);


    languages: { [key: string]: Language } = {};


    uploadingImages = false;
    percentage: number = 0;



    private disposeBag = new DisposeBag();


    constructor(
        private staticLabelsService: StaticLabelsService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe(() => {
                this.responseChange.emit(Object.values(this.languages));
            })
            .disposedBy(this.disposeBag);


        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setEditableModel(changes.value.currentValue)
    }

    private setEditableModel(value: Language[]): void {
        this.languages = {}
        if (value && value.length > 0) {
            value.forEach((element, index) => {
                this.languages[index] = element;
            });
        } else {
            this.languages = { 0: { id: guid(), name: "", level: "", nativeLanguage: false, notes: "" } };
        }
    }



    trackByUrl(_: number, url: string): string {
        return url;
    }

    languagesArray(): Language[] {
        return Object.values(this.languages);
    }


    onInputChange(): void {
        this.delayedChanges$.next();
    }


    trackById(_: number, entity: any): string {
        return entity.id;
    }

    label(labelId: string): string {
        if (
            this.translationLabels &&
            this.translationLabels[labelId] &&
            this.translationLabels[labelId][this.lang]
        ) {
            return this.translationLabels[labelId][this.lang];
        }
        return labelId;
    }

    addLanguage(): void {
        if (this.value && this.value.length > 0) {
            this.value = [...this.value, { id: guid(), name: "", level: "", nativeLanguage: false, notes: "" }];
        } else {
            this.value = [this.languages[0], { id: guid(), name: "", level: "", nativeLanguage: false, notes: "" }];
        }
        this.setEditableModel(this.value);
        this.onInputChange();
        this.chRef.detectChanges();
    }

    removeLanguage(language: Language): void {
        this.value = this.value.filter((p) => p.id !== language.id);
        this.setEditableModel(this.value);
        this.onInputChange()
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
