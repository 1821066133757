import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, filter, map, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { SendLinksForEmailRequest, SystemRequest } from 'src/app/api-clients/system-request-api-client.service';
import { browserLanguage, validateEmail } from 'src/app/core/functions';
import { SystemRequestService } from 'src/app/services/system-request.service';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Form } from 'src/modules/diversite/model/form/form';
import { FormElement, FormElementError, FormElementInput } from 'src/modules/diversite/model/form/form-element/form-element';
import { FormModule } from 'src/modules/diversite/model/form/form-module';
import { ContactPublicMapperService } from 'src/modules/fillout/mapper/contact-public-mapper.service';
import { FormMode } from 'src/modules/fillout/model/form-mode';
import { ResponseInput } from 'src/modules/fillout/model/response-input';
import { SubmissionReponses } from 'src/modules/fillout/model/submission-responses';
import { ContactPublic } from 'src/modules/fillout/services/recruit.service';

declare var $: any;

@Component({
    selector: 'fillout-form',
    templateUrl: './form.component.html',
    styleUrl: './form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent implements OnDestroy {
    private translationId = "mPPChPxCfo9iki7M9j7N";
    @Input() form: Form;
    @Input() mode: FormMode = "fillout";
    @Input() inputToHighlight: string[] = [];
    @Input() contact: ContactPublic;
    @Input() submitted = false;

    @Output() submitForm = new EventEmitter<SubmissionReponses>();

    @ViewChild("footerForm") footerForm: ElementRef;

    isFooterVisible$: Observable<boolean>;

    private uiState$ = new BehaviorSubject<UIState>("loading");

    private step$ = new BehaviorSubject<Step>("intro-email-request")

    private translationLabels: StaticLabels = {};
    private disposeBag = new DisposeBag();

    private selectedLang$ = new BehaviorSubject<string>(undefined);
    private formResponses = new Map<string, ResponseInput>();

    emailRequest: string = "";
    showErrorsBoard = false;
    contactIdfig: string;
    errors: FormElementError[][] = [];

    showErrorsBoardConscent = false;
    conscentErrors: FormElementError[][] = [];

    certifiedField = {};

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private staticLabelsService: StaticLabelsService,
        private systemRequestService: SystemRequestService,
        private contactPublicMapper: ContactPublicMapperService,
        private sanitizer: DomSanitizer,
        private host: ElementRef,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        if (this.mode === 'fillout') {

            this.route.queryParams.pipe(
                switchMap(p => {
                    if (p.systemRequest) {
                        const srId = p.systemRequest;
                        return this.systemRequestTreatmentAndGotoForm(srId)
                    }

                    if (p.skip) {
                        this.step$.next("form");
                    }
                    return of({});
                })
            ).subscribe().disposedBy(this.disposeBag)
        }

        if (this.mode === "admin_fix" || this.mode === "preview") {
            this.step$.next("form");
        }

        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);

        this.initForm();

    }

    toggleCertify(formElementId: string, checked: boolean): void {
        this.certifiedField[formElementId] = checked;
    }

    ngAfterViewInit(): void {
        if (this.mode === "fillout") {
            this.isFooterVisible$ = this.observeElementVisibility(this.footerForm);
        } else {
            this.isFooterVisible$ = of(true);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.form) {
            if (changes.form.currentValue && !changes.form.previousValue) {
                this.uiState$.next('form-filling');
            }
        }

        if (changes.submitted?.currentValue === true) {
            this.uiState$.next("submitted");
            this.goToEndMessageStep();
        }
    }


    private observeElementVisibility(element: ElementRef): Observable<boolean> {
        return new Observable<IntersectionObserverEntry[]>(observer => {
            const intersectionObserver = new IntersectionObserver(entries => {
                observer.next(entries);
            }, {
                // threshold: 1.0,
            });

            intersectionObserver.observe(element.nativeElement);

            return () => { intersectionObserver.disconnect(); };
        }).pipe(
            mergeMap((entries: IntersectionObserverEntry[]) => entries),
            map(entry => entry.isIntersecting),
            distinctUntilChanged()
        );
    }

    private initForm(): void {
        const browserLang = browserLanguage();
        if (this.form.languages.includes(browserLang)) {
            this.selectedLang$.next(browserLang);
        } else if (this.form.languages.length > 0) {
            this.selectedLang$.next(this.form.languages[0]);
        } else {
            this.selectedLang$.next("fr");
        }
    }



    logo(): string {
        return this.form && this.form.logo && this.form.logo !== "" ? this.form.logo : `assets/logo.jpg`;
    }

    label(labelId: string): string {
        if (
            this.translationLabels &&
            this.translationLabels[labelId] &&
            this.translationLabels[labelId][this.selectedLang]
        ) {
            return this.translationLabels[labelId][this.selectedLang];
        }
        return labelId;
    }

    get selectedLang(): string {
        return this.selectedLang$.value;
    }

    selectLang(lang: string): void {
        this.selectedLang$.next(lang);
    }

    isValidEmail(email: string): boolean {
        if (this.mode !== "preview") {
            return validateEmail(email);
        }
        return true;
    }

    get isSubmitting(): boolean {
        return this.uiState$.value === "submitting"
    }
    get isSubmitted(): boolean {
        return this.uiState$.value === "submitted"
    }
    get loading(): boolean {
        return this.uiState$.value === "loading"
    }
    get isFormFilling(): boolean {
        return this.uiState$.value === "form-filling"
    }

    get isIntroEmailRequestStep(): boolean {
        return this.step$.value === "intro-email-request"
    }

    get isCheckYourEmailStep(): boolean {
        return this.step$.value === "check-email"
    }
    get isFormStep(): boolean {
        return this.step$.value === "form"
    }
    get isConscentStep(): boolean {
        return this.step$.value === "conscent"
    }
    get isConfirmInfoStep(): boolean {
        return this.step$.value === "confirm-info"
    }

    trackById(index: number, entity: any): string {
        return entity.id;
    }

    trackByFnFormElement(index: number, formElement: FormElement): string {
        return `${formElement.id}-${formElement.name}`;
    }

    trackByError(index: number, errors: FormElementError[]): string {
        return `${index}`;
    }

    seeError(error: FormElementError): void {
        this.scrollToAndFlashElementId(error.formElement.id);
    }

    seeValidationErrors(): void {
        $(this.host.nativeElement).animate(
            {
                scrollTop: $(`.module.mandatory-module`).offset().top - 30,
            },
            250,
            () => {
                $(`.module.mandatory-module`)
                    .addClass("show")
                    .delay(3000)
                    .queue((next) => {
                        $(`.module.mandatory-module`).removeClass("show");
                        next();
                    });
            }
        );
    }

    private scrollToAndFlashElementId(elementId: string): void {
        $(this.host.nativeElement).animate(
            {
                scrollTop: $(`#${elementId}`).offset().top - 30,
            },
            250,
            () => {
                $(`#${elementId}`)
                    .addClass("show")
                    .delay(3000)
                    .queue((next) => {
                        $(`#${elementId}`).removeClass("show");
                        next();
                    });
            }
        );
    }

    value(formElement: FormElement): any {
        if (this.contact) {
            return this.contactValueForFormElement(formElement);
        }
    }

    private contactValueForFormElement(formElement: FormElement): any {
        if (formElement.isInput()) {
            const attribute = this.contact
                ? this.contact.attributes.find((attr) => {
                    return attr.index === formElement.name;
                })
                : undefined;
            return attribute && attribute.value ? attribute.value : "";
        } else {
            return undefined;
        }
    }

    onResponseChange(response: ResponseInput): void {
        this.formResponses.set(response.input.id, response);
        this.chRef.detectChanges();
    }

    formResponse(formElement: FormElement): any {
        return this.formResponses.get(formElement.id)?.value;
    }

    formInputs(): FormElement[] {
        return this.form.activeFormElements().filter(fe => fe.isInput());
    }

    sendLinksToEmail(): void {
        if (this.mode !== "preview") {

            this.step$.next("check-email");
            this.systemRequestService.add({
                email: this.emailRequest,
                currentUrl: window.location.href,
                requestType: "sendlinksforemail"
            } as SendLinksForEmailRequest).pipe(
                switchMap(sr => {
                    return this.systemRequestTreatmentAndGotoForm(sr.id)
                })
            ).subscribe().disposedBy(this.disposeBag)
        }
    }

    private systemRequestTreatmentAndGotoForm(srId: string): Observable<SystemRequest> {
        return this.systemRequestService.get(srId).pipe(
            filter((sr: any) => {
                return sr.status === "success" ? true : false;
            }),
            tap((sr: SendLinksForEmailRequest) => {
                this.setContactAndGoToForm(sr);
                this.router.navigate([], {
                    queryParams: {
                        'systemRequest': null,
                    },
                    queryParamsHandling: 'merge'
                })
                this.chRef.detectChanges();
            })
        )
    }

    setContactAndGoToForm(sr: SendLinksForEmailRequest): void {
        //TODO : BUILD CONTACT and GO TO FORM STEP
        if (sr.idfig && sr.contact) {
            this.contactIdfig = sr.idfig;
            this.contact = this.contactPublicMapper.mapDataToContactPublic(sr.contact);
            this.goToForm();
        }
    }

    goToForm(): void {
        this.uiState$.next("form-filling");
        this.step$.next("form");
    }

    goIntroEmailRequest(): void {
        this.uiState$.next("form-filling");
        this.step$.next("intro-email-request");
    }

    goNext(): void {
        if (this.isFormValid() || this.mode === "preview") {
            if (this.form.modules.find(m => m.isConscentModule && !m.deleted) ? true : false) {
                this.step$.next("conscent");
            } else {
                this.goToConfirm();
            }
        } else {
            this.showErrorsBoard = true;
        }
    }

    goToEndMessageStep(): void {
        this.uiState$.next("submitted");
    }

    conscentModule(): FormModule {
        return this.form.modules.find(m => m.isConscentModule);
    }

    goToConfirm() {
        if (this.isConscentValid() || this.mode === "preview") {
            this.step$.next("confirm-info");
        } else {
            this.showErrorsBoardConscent = true;
        }

    }

    isFormValid(): boolean {
        if (this.mode === "admin_fix") {
            return this.contactIdfig ? true : false;
        }

        const formInputs: FormElementInput[] = this.form
            .activeFormElements()
            .filter((fe) => fe.isInput()) as FormElementInput[];

        this.errors = formInputs
            .map((fi) => {
                const responseInput = this.formResponses.get(fi.id);
                const isValid = responseInput ? fi.validate(responseInput.value) : fi.validate(undefined);
                return isValid;
            })
            .filter((_) => _);



        const areConscentFieldsValid = this.form.toValidateFormElements().every(fe => {
            return this.certifiedField[fe.id] === true;
        });

        if (!areConscentFieldsValid) {
            this.errors = [...this.errors, [{
                type: "basic-info-validation"
            }]]
        }


        return this.errors.length === 0;
    }



    isConscentValid(): boolean {
        const formInputs: FormElementInput[] = this.form
            .conscentFormElements()
            .filter((fe) => {
                return fe.isInput();
            }) as FormElementInput[];

        this.conscentErrors = formInputs
            .map((fi) => {
                const responseInput = this.formResponses.get(fi.id);
                const isValid = responseInput ? fi.validate(responseInput.value) : fi.validate(undefined);
                return isValid;
            })
            .filter((_) => _);

        return this.conscentErrors.length === 0;
    }

    submit(): void {
        if (this.mode === "preview") {
            this.uiState$.next("submitted");
            this.step$.next("intro-email-request");
        } else {
            if (this.isFormValid()) {
                const responses: ResponseInput[] = Array.from(this.formResponses).map((a) => a[1]);

                this.uiState$.next("submitting");
                this.step$.next("intro-email-request");

                this.submitForm.emit({
                    idfig: this.contactIdfig,
                    contact: this.contact ? this.contact : undefined,
                    form: this.form,
                    responseInputs: responses,
                });
            }
        }
    }

    notConscentModules(form: Form): FormModule[] {
        return form.modules.filter(m => !m.isConscentModule);
    }

    safe(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}


export type UIState = "loading" | "form-filling" | "submitting" | "submitted"
type Step = "intro-email-request" | "check-email" | "form" | "conscent" | "confirm-info";