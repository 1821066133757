<div *ngIf="formElement" class="form-element">
    <diversite-form-text-edition
        *ngIf="formElement.type === 'text'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
    </diversite-form-text-edition>

    <diversite-form-radio-edition
        *ngIf="formElement.type === 'radio'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-radio-edition>

    <diversite-form-datetime-edition
        *ngIf="formElement.type === 'datetime'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-datetime-edition>

    <diversite-form-number-edition
        *ngIf="formElement.type === 'number'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-number-edition>

    <diversite-form-dropdown-edition
        *ngIf="formElement.type === 'dropdown'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-dropdown-edition>

    <diversite-form-union-edition
        *ngIf="formElement.type === 'union'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-union-edition>

    <diversite-form-textbox-edition
        *ngIf="formElement.type === 'textbox'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-textbox-edition>

    <diversite-form-checkbox-edition
        *ngIf="formElement.type === 'checkbox'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-checkbox-edition>

    <diversite-form-textarea-edition
        *ngIf="formElement.type === 'textarea'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-textarea-edition>

    <diversite-form-fileupload-edition
        *ngIf="formElement.type === 'fileupload'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-fileupload-edition>

    <diversite-form-imageupload-edition
        *ngIf="formElement.type === 'imageupload'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-imageupload-edition>

    <diversite-form-videoupload-edition
        *ngIf="formElement.type === 'videoupload'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-videoupload-edition>

    <diversite-form-table-edition
        *ngIf="formElement.type === 'table'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-table-edition>

    <diversite-form-weight-edition
        *ngIf="formElement.type === 'weight'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-weight-edition>

    <diversite-form-height-edition
        *ngIf="formElement.type === 'height'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-height-edition>

    <diversite-form-phone-edition
        *ngIf="formElement.type === 'phone'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-phone-edition>

    <diversite-form-phones-edition
        *ngIf="formElement.type === 'phones'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-phones-edition>

    <diversite-form-places-edition
        *ngIf="formElement.type === 'places'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-places-edition>

    <diversite-form-cars-edition
        *ngIf="formElement.type === 'cars'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-cars-edition>

    <diversite-form-equipments-edition
        *ngIf="formElement.type === 'equipments'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-equipments-edition>

    <diversite-form-animals-edition
        *ngIf="formElement.type === 'animals'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-animals-edition>

    <diversite-form-languages-edition
        *ngIf="formElement.type === 'languages'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-languages-edition>

    <diversite-form-availability-edition
        *ngIf="formElement.type === 'availability'"
        [lang]="lang"
        [readonlyElement]="readonlyElement"
        [formElement]="formElement"
        (formElementChange)="onFormElementChange($event)"
    >
        <diversite-tooltip-edit
            [lang]="lang"
            [readonlyElement]="readonlyElement"
            [tooltip]="formElement.description"
            (tooltipChanged)="onTooltipChanged($event, formElement)"
        >
        </diversite-tooltip-edit>
    </diversite-form-availability-edition>

    <diversite-quick-edit-input
        *ngIf="formElement && formElement.isInput() && !readonlyElement"
        [lang]="lang"
        [formElementInput]="formElement"
        (formElementInputChange)="onQuickEditChange($event)"
    >
    </diversite-quick-edit-input>
</div>
