<div [class.no-label]="!options.showLabel" class="clr-form-control" *ngIf="formElement">
    <label *ngIf="options.showLabel" class="clr-control-label">
        {{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>

    <div class="equipments">
        <div *ngFor="let equipment of equipmentsArray(); index as i" class="clr-control-container equipment">
            <input
                (change)="onInputChange()"
                class="clr-input"
                type="text"
                [placeholder]="label('equipmentName')"
                [(ngModel)]="equipment.name"
            />
            <input
                (change)="onInputChange()"
                class="clr-input"
                type="text"
                [placeholder]="label('equipmentDesc')"
                [(ngModel)]="equipment.description"
            />

            <div class="clr-file-wrapper">
                <label class="clr-control-label">
                    <span class="btn btn-primary btn-sm">{{ label("addPhotoButtonText") }}</span>
                    <input
                        [id]="formElement.id"
                        (change)="onFileSelected($event, i)"
                        class="clr-file clr-input"
                        type="file"
                        accept="image/*"
                        multiple
                        [name]="formElement.name + i"
                    />
                </label>
            </div>

            <div class="equipment-actions">
                <cds-icon
                    *ngIf="value && value.length > 1"
                    (click)="removeEquipment(equipment)"
                    shape="times"
                ></cds-icon>
            </div>

            <div class="equipment-images">
                <div
                    class="equipment-img"
                    *ngFor="let equipmentImg of equipment.pictures; trackBy: trackByUrl"
                    [style.background-image]="backgroundImage(equipmentImg)"
                >
                    <cds-icon (click)="deleteImage(i, equipmentImg)" class="delete" shape="times-circle"></cds-icon>
                </div>
            </div>
        </div>

        <clr-progress-bar *ngIf="uploadingImages" [clrValue]="percentage" clrMax="100"></clr-progress-bar>

        <a class="action-link" (click)="addEquipment()">{{ label("addEquipment") }}</a>
    </div>
</div>
