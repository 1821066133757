import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AttributeValue } from 'src/modules/fillout/services/recruit.service';

import { ContextData, ContextNode } from '../../model/context-node';
import { ActionType, Pane } from '../../model/pane';
import { SearchDefinition } from '../../model/search-definition';
import { SortDefinition } from '../../model/search-state';
import { AccessRoleService } from '../../services/access-role.service';
import { ContextMenuElement, ContextMenuService } from '../../services/context-menu.service';
import { ContextNodeService } from '../../services/context-node.service';
import { FormService } from '../../services/form.service';
import { ProjectService } from '../../services/projects.service';
import { SearchContextService } from '../../services/search-context.service';
import { SharedPackageService } from '../../services/shared-folder.service';
import { PaneReferenceService } from '../dashboard/services/pane-reference.service';
import { PaneContextData, PaneService } from '../dashboard/services/pane.service';

@Injectable({
    providedIn: "root",
})
export class ContextNodePaneActionsService {
    constructor(
        private paneReferenceService: PaneReferenceService,
        private paneService: PaneService,
        private contextNodeService: ContextNodeService,
        private searchContextService: SearchContextService,
        private contextMenuService: ContextMenuService,
        private projectService: ProjectService,
        private formService: FormService,
        private sharedPackageService: SharedPackageService,
        private accessRole: AccessRoleService,
        private db: AngularFirestore
    ) { }

    addSubFolder(node: ContextNode): void {
        this.contextNodeService.addChild(node, "folder").subscribe((_) => {
            this.contextMenuService.closeContextMenu();
        });
    }

    private shareProject(projectId: string): void {
        this.openActionPaneAtIndex("share-project", 1, { projectId }).pipe(take(1)).subscribe(_ => this.contextMenuService.closeContextMenu());
    }

    addSharedFolder(node: ContextNode, projectId: string): void {
        this.sharedPackageService.createPackage(projectId).pipe(switchMap(pckage => {
            return this.contextNodeService.editNode(node.assignSharedPackage(pckage.id))
        }), take(1)).subscribe((_) => {
            this.contextMenuService.closeContextMenu();
        });
    }

    openProjectsPane(): Observable<Pane> {
        const pane = new Pane({
            type: "projects"
        });

        return this.paneReferenceService.addPane(pane, 1).pipe(take(1));
    }

    private addSearch(parentNode?: ContextNode, projectId?: string): void {

        this.searchContextService.createNewSearchDefinition().pipe(
            switchMap((s) => {
                const contextData: ContextData = { searchContextId: s.id };
                return this.contextNodeService.addChild(parentNode, "search", contextData, "Recherche");
            }),
            switchMap((addChildResponse) => {
                return this.openSearchPaneForNode(addChildResponse.child, projectId);
            })
        ).subscribe((_) => {
            this.contextMenuService.closeContextMenu();
        });
    }

    openActionPaneAtIndex(actionType: ActionType, indexOfPane: number, contextData: PaneContextData, projectId?: string): Observable<Pane> {
        let pId;
        if (projectId) {
            pId = projectId;
        } else if (contextData?.projectId) {
            pId = contextData?.projectId;
        }

        const pane = new Pane({
            type: "action",
            projectId: pId || null,
            contextNodeId: contextData.contextNodeId || null,
            contextData: { actionType, ...contextData },
        });

        return this.paneReferenceService.addPane(pane, indexOfPane).pipe(take(1));
    }

    openDatabaseSearchPane(dbId?: string, order?: SortDefinition): Observable<Pane> {
        const pane = new Pane({
            type: "search",
            locked: true,
        });

        const contextData: ContextData = {};


        let search = SearchDefinition.DEFAULT_SEARCH_DEFINITION(this.db.createId()).change({
            datasource: dbId || null
        });
        return this.searchContextService
            .createNewSearchDefinition(search)
            .pipe(
                switchMap((s) => {
                    contextData.searchContextId = s.id;
                    return this.paneReferenceService.addPane(pane.change({ contextData }));
                })
            );

    }

    openContactProfilePane(contactId: string, projectId?: string, formId?: string): Observable<Pane> {
        return this.paneReferenceService
            .addPane(
                new Pane({
                    type: "contact",
                    projectId: projectId || null,
                    contextData: {
                        contactId,
                        formId: formId || null
                    },
                })
            )
    }

    openActivitiesPane(): Observable<Pane> {
        const pane = new Pane({
            type: "activities",
            contextData: {}
        });

        return this.paneReferenceService.addPane(pane);


    }

    addForm(node: ContextNode, projectId: string): void {
        this.formService.createForm(projectId).pipe(switchMap(response => {
            return this.contextNodeService.editNode(node.addFormIds([response.form.id])).pipe(map(_ => response))
        })).subscribe((response) => {
            this.contextMenuService.closeContextMenu();
            this.openEditFormPane(response.form.id, node.id, projectId);
        });
    }

    addProject(): void {
        this.projectService
            .addNewProject()
            .pipe(
                switchMap((p) => {
                    return this.contextNodeService.addRootElement("project", { projectId: p.id })
                })
            )
            .subscribe((_) => {
                this.contextMenuService.closeContextMenu();
            });
    }

    openSearchPaneForNode(node: ContextNode, projectId?: string): Observable<Pane> {
        if (node.type === "search") {
            return forkJoin(
                this.paneReferenceService.currentLayout.map((paneContent) =>
                    this.paneService.pane(paneContent.paneRef.id, { listen: false })
                )
            ).pipe(
                switchMap((panes: Pane[]) => {
                    const paneForSearch = panes.find((p) => p.contextNodeId === node.id);
                    if (paneForSearch) {
                        return of(paneForSearch);
                    } else {
                        const pane = new Pane({
                            type: "search",
                            locked: true,
                            contextData: node.contextData,
                            projectId,
                            contextNodeId: node.id || null,
                        });
                        return this.paneReferenceService.addPane(pane);
                    }
                })
            );
        } else if (node.type === "project" || node.type === "folder" || node.type === "preselection" || node.type === 'reject') {
            return this.openSearchPane(SearchDefinition.DEFAULT_SEARCH_DEFINITION(this.db.createId()).setContextNode(node.id), projectId || null, node.id || null, "classification")
        }
        return of(undefined);
    }



    openSearchPane(searchDef?: SearchDefinition, projectId?: string, nodeId?: string, tabOpen?: string): Observable<Pane> {
        const pane = new Pane({
            type: "search",
            projectId: projectId || null,
            locked: true,
            contextNodeId: nodeId || null,
            contextData: {
                tabOpen: tabOpen || "search"
            }
        });

        let search = searchDef || SearchDefinition.DEFAULT_SEARCH_DEFINITION(this.db.createId()).change({ projectId });
        return this.searchContextService
            .createNewSearchDefinition(search)
            .pipe(
                switchMap((s) => {
                    return this.paneReferenceService.addPane(pane.change({ contextData: { ...pane.contextData, searchContextId: s.id } }));
                })
            );

    }

    openProjectConfigurationPane(projectId?: string, nodeId?: string): Observable<Pane> {
        const pane = new Pane({
            type: "project-config",
            projectId: projectId || null,
            locked: true,
            contextNodeId: nodeId || null,
        });
        return this.paneReferenceService.addPane(pane);

    }

    openClassificationPane(classificationId: string, index: number, projectId?: string): Observable<Pane> {
        return this.paneReferenceService
            .addPane(
                new Pane({
                    type: "classification",
                    projectId: projectId || null,
                    locked: true,
                    contextData: {
                        classificationId
                    },
                }),
                index
            )
    }

    openClassificationStatsPane(classificationId: string, classificationGroupId: string, atIndex: number, projectId?: string): Observable<Pane> {
        return this.paneReferenceService
            .addPane(
                new Pane({
                    type: "classification-stats",
                    projectId: projectId || null,
                    contextData: {
                        classificationId,
                        classificationGroupId
                    },
                }),
                atIndex
            )
    }

    openContactsPane(node: ContextNode, projectId: string): void {
        const pane = new Pane({
            type: "contacts",
            projectId,
            contextNodeId: node?.id || null,
        });
        this.paneReferenceService.addPane(pane).subscribe((_) => { });
    }

    openFormPane(formId: string, nodeId: string, projectId?: string, respondantContactId?: string, formFieldsToHighlight?: string[]): void {
        const pane = new Pane({
            type: "form-preview",
            projectId,
            contextData: {
                formId,
                contactId: respondantContactId || null,
                formFieldsToHighlight: formFieldsToHighlight || []
            },
            contextNodeId: nodeId,
        });
        this.paneReferenceService.addPane(pane).subscribe((_) => { });
    }

    openUpdatePhoto(attributeValues: AttributeValue[], contactId: string) {
        const pane = new Pane({
            type: "upload-photo-preview",
            contextData: { attributeValues, contactId: contactId || null },
        });
        this.paneReferenceService.addPane(pane).subscribe((_) => { });
    }


    openReportEditPane(reportId: string, node?: ContextNode, projectId?: string): void {
        const pane = new Pane({
            type: "report-edit",
            projectId: projectId ? projectId : null,
            contextNodeId: node?.id ? node.id : null,
            contextData: { reportId }
        });
        this.paneReferenceService.addPane(pane).subscribe((_) => { });
    }

    openEditFormPane(formId: string, nodeId: string, projectId?: string): void {
        const pane = new Pane({
            type: "form-edit",
            projectId,
            contextData: { formId },
            contextNodeId: nodeId,
        });
        this.paneReferenceService.addPane(pane).subscribe((_) => { });
    }

    removeNode(node: ContextNode): void {
        this.closeNodeChildrenPanes(node)
            .pipe(
                switchMap((_) => {
                    if (node.type === "project" && node.contextData?.projectId) {
                        return this.contextNodeService.removeNodeFromNodeView(node.id).pipe(
                            switchMap(() => {
                                return this.projectService.archiveProject(node.contextData.projectId);
                            })
                        );
                    } else {
                        return this.contextNodeService.removeNode(node);
                    }
                })
            )
            .subscribe((_) => {
                this.contextMenuService.closeContextMenu();
            });
    }

    private closeNodeChildrenPanes(node: ContextNode): Observable<void> {
        let obs;
        if (node.children && node.children.length > 0) {
            obs = forkJoin(
                node.children.map((nodeId) => {
                    return this.contextNodeService.node(nodeId, { listen: false }).pipe(
                        switchMap((n) => {
                            return this.closeNodeChildrenPanes(n);
                        })
                    );
                })
            );
        } else {
            obs = of(undefined);
        }

        return obs.pipe(
            switchMap((_) => {
                return this.paneService
                    .panesForNode(node.id)
                    .pipe(
                        switchMap((panes) => {
                            if (panes && panes.length > 0) {
                                return forkJoin(panes.map((p) => this.paneReferenceService.removePane(p.id)));
                            } else {
                                return of(undefined);
                            }
                        })
                    )
                    .pipe(map((_) => undefined));
            })
        );
    }

    menuActionsForNode(
        nodeData: { node: ContextNode; projectId?: string },
        callback?: (action?: MenuActionCallbackAction) => void
    ): ContextMenuElement[] {
        const actions = [];

        if (nodeData.node.type === "project") {

            if (this.accessRole.hasAccess({ type: "segment", segment: "searchOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "search",
                    label: "Ajouter une recherche",
                    action: () => {
                        this.addSearch(nodeData.node, nodeData.projectId);
                        callback();
                    },
                });
            }

            if (this.accessRole.hasAccess({ type: "segment", segment: "folderOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "folder",
                    label: "Ajouter un dossier",
                    action: () => {
                        this.addSubFolder(nodeData.node);
                        callback();
                    },
                });
            }

            if (this.accessRole.hasAccess({ type: "segment", segment: "projectOperation", permissions: ["update", "read", "write"] })) {
                actions.push({
                    icon: "share",
                    label: "Partager un projet",
                    action: () => {
                        this.shareProject(nodeData.projectId);
                        callback();
                    },
                });
            }

            if (this.accessRole.hasAccess({ type: "segment", segment: "formOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "form",
                    label: "Ajouter un formulaire",
                    action: () => {
                        this.addForm(nodeData.node, nodeData.projectId);
                        callback("add-form");
                    },
                });
            }

            if (this.accessRole.hasAccess({ type: "segment", segment: "sharedPackageOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "file-share",
                    label: "Ajouter un dossier de partage",
                    action: () => {
                        this.addSharedFolder(nodeData.node, nodeData.projectId);
                        callback("add-shared-folder");
                    },
                });
            }

            if (this.accessRole.hasAccess({ type: "segment", segment: "folderOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "users",
                    label: "Ajouter une liste d'idfig",
                    action: () => {
                        this.contextMenuService.closeContextMenu();
                        callback("add-idfig-list");
                    },
                });
            }

            if (this.accessRole.hasAccess({ type: "segment", segment: "reportOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "clipboard",
                    label: "Créer un rapport",
                    action: () => {
                        callback("generate-report");
                    },
                });
            }

        }

        if (nodeData.node.type === "folder") {

            if (this.accessRole.hasAccess({ type: "segment", segment: "searchOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "search",
                    label: "Ajouter une recherche",
                    action: () => {
                        this.addSearch(nodeData.node, nodeData.projectId);
                        callback();
                    },
                });
            }

            if (this.accessRole.hasAccess({ type: "segment", segment: "folderOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "folder",
                    label: "Ajouter un dossier",
                    action: () => {
                        this.addSubFolder(nodeData.node);
                        callback();
                    },
                });
            }

            if (this.accessRole.hasAccess({ type: "segment", segment: "formOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "form",
                    label: "Ajouter un formulaire",
                    action: () => {
                        this.addForm(nodeData.node, nodeData.projectId);
                        callback("add-form");
                    },
                });
            }

            if (this.accessRole.hasAccess({ type: "segment", segment: "reportOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "clipboard",
                    label: "Créer un rapport",
                    action: () => {
                        callback("generate-report");
                    },
                });
            }

        }

        if (nodeData.node.type === "search") {

            if (this.accessRole.hasAccess({ type: "segment", segment: "folderOperation", permissions: ["write"] })) {
                actions.push({
                    icon: "folder",
                    label: "Ajouter un dossier",
                    action: () => {
                        this.addSubFolder(nodeData.node);
                        callback();
                    },
                });
            }
        }


        if (this.accessRole.hasAccess({ type: "segment", segment: "projectOperation", permissions: ["update"] })) {
            actions.push({
                icon: "trash",
                label: "Effacer",
                action: () => {
                    this.removeNode(nodeData.node);
                    callback();
                },
            });
        }

        return actions;
    }

    menuActionsContactsForNode(callback?: (action: ActionForContactNode) => void): ContextMenuElement[] {
        const actions = [];

        if (this.accessRole.hasAccess({ type: "segment", segment: "contactOperation", permissions: ["update"] })) {
            actions.push({
                icon: "note",
                label: "Modifier les membres",
                action: () => {
                    callback("mass-edit-contacts");
                },
            });
        }

        if (this.accessRole.hasAccess({ type: "segment", segment: "formOperation", permissions: ["write"] })) {
            actions.push({
                icon: "envelope",
                label: "Envoyer un formulaire",
                action: () => {
                    callback("send-form-to-contacts");
                },
            });
        }

        if (this.accessRole.hasAccess({ type: "segment", segment: "contactOperation", permissions: ["read"] })) {
            actions.push({
                icon: "export",
                label: "Exporter",
                action: () => {
                    callback("export");
                },
            });
        }

        if (this.accessRole.hasAccess({ type: "segment", segment: "reportOperation", permissions: ["write"] })) {
            actions.push({
                icon: "clipboard",
                label: "Créer un rapport",
                action: () => {
                    callback("generate-report");
                },
            });
        }


        return actions;
    }

    menuActionsFormsForNode(
        nodeData: { node: ContextNode; projectId: string },
        callback?: () => void
    ): ContextMenuElement[] {
        const actions = [];

        if (this.accessRole.hasAccess({ type: "segment", segment: "formOperation", permissions: ["write"] })) {
            actions.push({
                icon: "plus",
                label: "Ajouter un formulaire",
                action: () => {
                    this.addForm(nodeData.node, nodeData.projectId);
                    callback();
                },
            });
        }

        return actions;
    }
}

export type ActionForContactNode =
    | "mass-edit-contacts"
    | "send-form-to-contacts"
    | "export"
    | "generate-report"
    | "remove-contacts";

export type MenuActionCallbackAction = "generate-report" | "add-search" | "add-form" | "add-shared-folder" | "add-idfig-list";
